import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import { kpButtonStyle, getKpImage, KpLastSlideImage } from "../components/slideshows/KpSlideshow"
import Slide from "../components/slideshows/Slide"
import LastSlide from "../components/slideshows/LastSlide"
import Nav from "../components/slideshows/Nav"
import Overview from "../components/slideshows/Overview"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import kpLogoRound from "../images/slideshow/kp/KP_Logo_Round.jpg"
import kpLogo from "../images/slideshow/kp/NW_KP_Bild_Lands_1_a.jpg"
import kpLogoEng from "../images/slideshow/kp/NW_KP_Bild_Lands_1_a_Eng.jpg"

const Wrapper = styled.div`
  min-height: 100%;
  background: ${props => props.bgColor};
`

const KpLogo = styled.img`
  border-radius: 50%;
`

export default function KpSlideshowTemplate({
  data: { kpSlideshowYaml, allKpSlideshowYaml },
  pageContext: {
    lang,
    pagePath,
    basePath,
    currentSlide,
    isLast = false,
    order,
  },
}) {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "kpSlideshowTitle" })
  const theme = { themeColor: "#a3ccee", textColor: "black", bgColor: "#69c" }
  const lastSlideImage = isLast ? <KpLastSlideImage /> : null
  const image = isLast ? null : getKpImage(kpSlideshowYaml)
  const logo = lang === "sv" ? kpLogo : kpLogoEng
  const roomSymbol = (
    <KpLogo src={kpLogoRound}
      alt={`KP ${intl.formatMessage({ id: "logotype" })}`}
      className="bigger"
    />
  )

  return (
    <Wrapper bgColor={theme.bgColor}>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={title}
        image={{ url: kpSlideshowYaml.imageLandscape, alt: kpSlideshowYaml.imageAltText }}
      />
      <Header
        lang={lang}
        room={3}
        color={theme.bgColor}
        roomSymbol={roomSymbol}
        isTransparent
      />
      <Overview
        lang={lang}
        basePath={basePath}
        currentSlide={currentSlide}
        slides={allKpSlideshowYaml.nodes}
        logo={logo}
        bgColor={theme.bgColor}
      />
      {isLast ?
        <LastSlide
          lang={lang}
          basePath={basePath}
          image={lastSlideImage}
          buttonStyle={kpButtonStyle}
        />
      :
        <Slide
          image={image}
          sound={kpSlideshowYaml.sound}
          text={kpSlideshowYaml.text}
          slideshowTheme={theme}
          isMarkdown
        />
      }
      <Nav
        currentSlide={currentSlide}
        isLast={isLast}
        color={theme.bgColor}
        prevLink={currentSlide > 1 ? `/${lang}/${basePath}/${currentSlide - 1}` : null}
        nextLink={!isLast ? `/${lang}/${basePath}/${currentSlide + 1}` : null}
      />
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $order: Float) {
    kpSlideshowYaml(lang: { eq: $lang }, order: { eq: $order }) {
      text {
        html
      }
      sound
      imagePortrait
      imageLandscape
      imageAltText
      caption
      day
      time
    }
    allKpSlideshowYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        order
        imageLandscape
      }
    }
  }
`
